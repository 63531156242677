import NavbarEnum from '../enumerators/navbar.enum'

export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
    }

    static sticky() {
        let lastScrollTop = 0
        let stateOnTop = true

        $(window).on('load scroll', function () {
            const offset = 100 // Navbar default height
            const offsetOnScroll = 100 // Navbar height on scroll
            const navbar = $(NavbarEnum.NAVBAR)
            const scrollTop = $(this).scrollTop()

            // Hidden on scroll comportment
            if (scrollTop >= lastScrollTop && scrollTop > $(NavbarEnum.NAVBAR).outerHeight()) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
            } else {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            if ($(NavbarEnum.BODY).hasClass('home-banner-is-fixed')) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            // On top/on scroll switch
            if (scrollTop > offset && stateOnTop === true) {
                stateOnTop = false
                $(NavbarEnum.NAVBAR).removeClass('navbar--ontop')
                $(NavbarEnum.NAVBAR).addClass('navbar--onscroll')
            } else if (scrollTop < offsetOnScroll && stateOnTop === false) {
                stateOnTop = true
                $(NavbarEnum.NAVBAR).removeClass('navbar--onscroll')
                $(NavbarEnum.NAVBAR).addClass('navbar--ontop')
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $(NavbarEnum.LOGO)
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        //Open/Close menu
        $(NavbarEnum.BURGER_MENU_TRIGGER).on('click', () => {
            $(NavbarEnum.BODY).toggleClass('menu-open')

            if ($(NavbarEnum.BODY).hasClass('menu-submenu-open')) {
                $(NavbarEnum.BODY).removeClass('menu-submenu-open')
            }

            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')

            //Hide ecomode
            $(NavbarEnum.ECOMODE).addClass('eco-bar--hidden')
        })

        //Open/Close submenu
        $(NavbarEnum.BURGER_MENU_MORE).on('click', function () {
            $(this).parent().parent().toggleClass('submenu-open')
            $(NavbarEnum.BODY).addClass('menu-submenu-open')
        })

        $(NavbarEnum.BURGER_MENU_PREV).on('click', (e) => {
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
            $(NavbarEnum.BODY).removeClass('menu-submenu-open')
        })
    }
}
