export default class FooterMapComponent {
    constructor() {
        FooterMapComponent.init()
    }

    static init() {
        const mapContainer = '.js-footer-map'
        const mapElement = '.js-footer-map-image'
        const desktopMapUrl = $(mapContainer)?.data('customer-footer-map-url-desktop')
        const mobileMapUrl = $(mapContainer)?.data('customer-footer-map-url-mobile')

        if (window.matchMedia('(max-width: 1000px)').matches) {
            $(mapElement).attr('src', mobileMapUrl)
        } else {
            $(mapElement).attr('src', desktopMapUrl)
        }
    }
}
