import FooterMapComponent from './components/footer-map.component'
import ToolsbarComponent from './components/toolsbar.component'
import HeaderService from './services/header.service'
import LayoutService from './services/layout.service'

document.addEventListener('DOMContentLoaded', () => {
    new HeaderService()
    new LayoutService()
    new ToolsbarComponent()
    new FooterMapComponent()
})
